import React from "react"
import PrivacyPolicyTemplate from "../../../components/PrivacyPolicyTemplate"

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyTemplate
      name="Vision Future Nice"
      mail="reception@visionfuture.fr"
    />
  )
}

export default PrivacyPolicy
